import React, { useEffect } from "react";
import "../../styles/main.css";
import { Appraisal } from "../../backend-generated-types";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import RadarChart from "../custom_build_analytics/RadarChart";
import PieChart from "../custom_build_analytics/PieChart";
import LineGraph from "../custom_build_analytics/LineGraph";
import { Component_Type_Strings } from "../../constants";
import { gql, useLazyQuery } from "@apollo/client";
import PaidIcon from "@mui/icons-material/Paid";
import { navigate } from "gatsby";

type Props = {
  custom_build_id: string;
  appraisal?: Appraisal;
  handle_appraise_modal_open?: () => void;
};

type Part_Breakdown_Json = {
  id: string;
  type: Component_Type_Strings;
  price: number;
};

const Appraisal_Tags = (props: Props) => {
  const appraisal_part_list: Part_Breakdown_Json[] =
    props.appraisal?.part_breakdown ?? [];

  useEffect(() => {
    if (!called && !!props.appraisal) {
      query_analytics();
    }
  }, [props.custom_build_id, props.appraisal]);

  const [query_analytics, { called, loading, data }] = useLazyQuery(gql`
        query Get_Custom_Build_Analytics_By_Id {
            custom_build_by_id(id: "${props.custom_build_id}")
            {
                buying_power_distribution {
                    case {
                        standard_expected
                        build
                    }
                    case_fan {
                        standard_expected
                        build
                    }
                    cpu {
                        standard_expected
                        build
                    }
                    cpu_cooler {
                        standard_expected
                        build
                    }
                    gpu {
                        standard_expected
                        build
                    }
                    motherboard {
                        standard_expected
                        build
                    }
                    operating_system {
                        standard_expected
                        build
                    }
                    psu {
                        standard_expected
                        build
                    }
                    ram {
                        standard_expected
                        build
                    }
                    storage {
                        standard_expected
                        build
                    }
                }
                estimated_value_over_time(start_time: "${new Date("2022-01-01")
      .toISOString()
      .substring(0, 10)}", end_time: "${new Date("2026-01-01")
        .toISOString()
        .substring(0, 10)}", number_points: ${10}) {
                    dollars
                    time
                }
                ${appraisal_part_list.map((p, i) => {
          return `
                            build_component_${i}_value_over_time: estimated_component_value_over_time(start_time: "${new Date(
            "2022-01-01"
          )
              .toISOString()
              .substring(0, 10)}", end_time: "${new Date("2026-01-01")
                .toISOString()
                .substring(0, 10)}", number_points: ${10}, component_id: "${p.id
            }", component_type: "${p.type}") {
                                dollars
                                time
                            }
                        `;
        })}
            }
        }`);

  return (
    <>
      <main>
        <Typography variant={"h3"}>
          Advanced Analytics
          <Tooltip title="Get an appraisal to unlock your build's analytics!">
            <PaidIcon color={"primary"} sx={{ pl: 1 }} />
          </Tooltip>
        </Typography>
        <>
          {props.appraisal?.is_completed &&
            !!props.appraisal?.price &&
            called &&
            !loading &&
            !!data && (
              <Grid container sx={{ width: "100%", height: "100%" }}>
                {appraisal_part_list.length >= 4 && (
                  <Grid item>
                    <RadarChart
                      title={
                        "Component Price Distribution Compared to Standard"
                      }
                      width={500}
                      height={425}
                      data={data.custom_build_by_id.buying_power_distribution}
                    />
                  </Grid>
                )}
                {appraisal_part_list.length >= 2 && (
                  <Grid item>
                    <PieChart
                      title={"Component Price Distribution"}
                      width={400}
                      height={425}
                      data={data.custom_build_by_id.buying_power_distribution}
                    />
                  </Grid>
                )}
                {appraisal_part_list.length >= 2 &&
                  !!data.custom_build_by_id.estimated_value_over_time && (
                    <Grid item key="estimated-value">
                      <LineGraph
                        title={
                          "BitBot valuation compared with BitLot composite valuation"
                        }
                        width={800}
                        height={425}
                        data={data.custom_build_by_id.estimated_value_over_time}
                        reference_point={{
                          dollars: props.appraisal?.price,
                          time: props.appraisal?.updated_at,
                        }}
                      />
                    </Grid>
                  )}
                {!!props.appraisal &&
                  !!appraisal_part_list &&
                  appraisal_part_list.map((p, i) => {
                    return (
                      <Grid item key={i}>
                        <LineGraph
                          title={`BitBot valuation for ${p.type} compared with BitLot composite valuation`}
                          width={500}
                          height={425}
                          data={
                            data.custom_build_by_id[
                            `build_component_${i}_value_over_time`
                            ]
                          }
                          reference_point={{
                            dollars: p.price,
                            time: props.appraisal?.updated_at,
                          }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          {(!props.appraisal?.is_completed || !props.appraisal?.price) && (
            <Grid
              container
              sx={{ width: "100%", height: "100%", margin: "2rem 0" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant={"h4"}
                  textAlign={"center"}
                >{`Get your build appraised to see advanced analytical insights!`}</Typography>
                <Typography
                  variant={"h4"}
                  textAlign={"center"}
                  sx={{ marginBottom: "2rem" }}
                >{`Here are some sample charts and graphs we like to use:`}</Typography>
              </Grid>
              <Grid item xs={6}>
                <RadarChart
                  title={"Component Price Distribution Compared to Standard"}
                  width={500}
                  height={425}
                />
              </Grid>
              <Grid item xs={6}>
                <PieChart
                  title={"Component Price Distribution"}
                  width={500}
                  height={425}
                />
              </Grid>
              <Grid item xs={12}>
                <LineGraph
                  title={
                    "BitBot valuation compared with BitLot composite valuation"
                  }
                  width={600}
                  height={425}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{ m: 0.5 }}
                  variant={"contained"}
                  onClick={
                    props.handle_appraise_modal_open
                      ? () => props.handle_appraise_modal_open!()
                      : () => navigate("/buy-appraisals")
                  }
                >
                  Get appraised today!
                  <PaidIcon sx={{ pl: 1 }} />
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      </main>
    </>
  );
};

export default Appraisal_Tags;
