import { Grid, Typography } from "@mui/material";
import * as React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import { Buying_Power_Distribution } from "../../backend-generated-types";

type Props = {
  title?: string;
  width: number;
  height: number;
  data?: Buying_Power_Distribution;
};

type Distribution_Data = {
  component: string;
  standard_percentage: number;
  build_percentage: number;
};

function buying_power_data_to_distribution_data(
  backend_data: Buying_Power_Distribution
): Distribution_Data[] {
  return [
    {
      component: "case",
      build_percentage: backend_data.case.build,
      standard_percentage: backend_data.case.standard_expected,
    },
    {
      component: "case_fan",
      build_percentage: backend_data.case_fan.build,
      standard_percentage: backend_data.case_fan.standard_expected,
    },
    {
      component: "cpu",
      build_percentage: backend_data.cpu.build,
      standard_percentage: backend_data.cpu.standard_expected,
    },
    {
      component: "cpu_cooler",
      build_percentage: backend_data.cpu_cooler.build,
      standard_percentage: backend_data.cpu_cooler.standard_expected,
    },
    {
      component: "gpu",
      build_percentage: backend_data.gpu.build,
      standard_percentage: backend_data.gpu.standard_expected,
    },
    {
      component: "motherboard",
      build_percentage: backend_data.motherboard.build,
      standard_percentage: backend_data.motherboard.standard_expected,
    },
    {
      component: "operating_system",
      build_percentage: backend_data.operating_system.build,
      standard_percentage: backend_data.operating_system.standard_expected,
    },
    {
      component: "psu",
      build_percentage: backend_data.psu.build,
      standard_percentage: backend_data.psu.standard_expected,
    },
    {
      component: "ram",
      build_percentage: backend_data.ram.build,
      standard_percentage: backend_data.ram.standard_expected,
    },
    {
      component: "storage",
      build_percentage: backend_data.storage.build,
      standard_percentage: backend_data.storage.standard_expected,
    },
  ];
}

const chart_style = {
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
};

const sample_data: Distribution_Data[] = [
  {
    component: "cpu",
    standard_percentage: 22,
    build_percentage: 30,
  },
  {
    component: "psu",
    standard_percentage: 7,
    build_percentage: 10,
  },
  {
    component: "motherboard",
    standard_percentage: 11,
    build_percentage: 20,
  },
  {
    component: "ram",
    standard_percentage: 3,
    build_percentage: 3,
  },
  {
    component: "storage",
    standard_percentage: 5,
    build_percentage: 10,
  },
  {
    component: "cpu_cooler",
    standard_percentage: 4,
    build_percentage: 2,
  },
  {
    component: "gpu",
    standard_percentage: 36,
    build_percentage: 20,
  },
  {
    component: "case",
    standard_percentage: 4,
    build_percentage: 5,
  },
];

const BitLotRadarChart = (props: Props) => {
  return (
    <main style={chart_style}>
      <Grid container sx={{ width: props.width, height: props.height }} m={2}>
        <Grid item xs={12}>
          {!!props.title && (
            <Typography variant={"h6"} sx={{ textAlign: "center" }}>
              {props.title}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <RadarChart
              outerRadius="80%"
              data={
                props.data
                  ? buying_power_data_to_distribution_data(props.data)
                  : sample_data
              }
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="component" />
              <PolarRadiusAxis angle={60} domain={[0, 50]} />
              <Radar
                name="Standard price distribution for builds like this"
                dataKey="standard_percentage"
                stroke="#39B54A"
                fill="#39B54A"
                fillOpacity={0.2}
              />
              <Radar
                name="This build's price distribution"
                dataKey="build_percentage"
                stroke="#231F20"
                fill="#231F20"
                fillOpacity={0.2}
              />
              <Legend align="left" />
            </RadarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </main>
  );
};

export default BitLotRadarChart;
