import * as React from "react";
import { Box, Fade, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Recommended_Upgrade } from "../../backend-generated-types";
import { useEffect, useState } from "react";
import MinimizeIcon from '@mui/icons-material/Minimize';
import ExpandIcon from '@mui/icons-material/Expand';
import { generate_part_page_link } from "../../helpers/link_helper";
import { gql, useQuery } from "@apollo/client";


interface Props {
  recommendations: Recommended_Upgrade[]
}

const RecommendationsPanel = (props: Props) => {

  const { data: me_data, loading: me_loading, error: me_error } = useQuery(gql`
        query {
            me
            {
                id
            }
        }
    `,
    {
      fetchPolicy: 'cache-first',
    });

  const [is_minimized, set_minimized] = useState<boolean>(!!localStorage.getItem(`user_${me_data.me.id}_minimize_recs`));

  useEffect(() => {
    localStorage.setItem(`user_${me_data.me.id}_minimize_recs`, `${is_minimized ? is_minimized : ''}`);
  }, [is_minimized]);

  const columns: GridColDef[] = [
    {
      field: 'part_name',
      headerName: 'Part Name',
      width: 250,
      renderCell: (params) =>
        <Link underline="none" href={!!params.row.part_type && !!params.row.id ? generate_part_page_link(params.row.part_type, params.row.id) : undefined}>{params.row.part_name}</Link>,
    },
    { field: 'part_type', headerName: 'Type', width: 70 },
    { field: 'price', headerName: 'Avg Price', width: 70, renderCell: (params) => `$${params.row.price}`.toLocaleString() },
    {
      field: 'age',
      headerName: 'Link',
      width: 100,
      renderCell: (params) =>
        params.row.link ? <Link underline="none" href={params.row.link}>Amazon</Link> : '',
    },
  ];

  const rows = props.recommendations?.map(r => { return { id: r.id, price: r.market_price, part_type: r.component_type, part_name: r.base_name, link: r.amazon_link } }) ?? [];

  return (
    <Fade in={true}>
      <Paper
        variant="elevation"
        square
        elevation={8}
        sx={{ position: 'fixed', bottom: 16, right: 16, width: 448, height: !is_minimized ? 424 : 48, zIndex: 10 }}>
        <Box
          display="flex"
          alignItems="center"
          sx={{ position: 'absolute', right: 0 }}
        >
          <IconButton
            aria-label="delete"
            sx={{ position: 'absolute', right: 4, top: 4, width: 32, height: 32 }}
            onClick={() => set_minimized(!is_minimized)}
          >
            {!is_minimized && <MinimizeIcon sx={{ position: 'absolute', top: -3 }} />}
            {is_minimized && <ExpandIcon sx={{ position: 'absolute' }} />}
          </IconButton>
        </Box>
        <Grid container>
          <Grid item xs={12} m={1} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Recommended Upgrades (Beta)</Typography>
          </Grid >
        </Grid >
        {!is_minimized &&
          <>
            <Grid container>
              <Grid item xs={12} m={1} sx={{ textAlign: 'center' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  rowHeight={30}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 8 },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </>}

      </Paper >
    </Fade >
  );
}

export default RecommendationsPanel;