import { Grid, Typography } from "@mui/material";
import * as React from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";
import { Buying_Power_Distribution } from "../../backend-generated-types";
import { useEffect, useState } from "react";

type Props = {
  title?: string;
  width: number;
  height: number;
  data?: Buying_Power_Distribution;
};

type Distribution_Data = {
  component: string;
  build_percentage: number;
};

const chart_style = {
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
  fontSize: 13,
};

const sample_data: Distribution_Data[] = [
  {
    component: "cpu",
    build_percentage: 30,
  },
  {
    component: "psu",
    build_percentage: 10,
  },
  {
    component: "motherboard",
    build_percentage: 20,
  },
  {
    component: "ram",
    build_percentage: 3,
  },
  {
    component: "storage",
    build_percentage: 10,
  },
  {
    component: "cpu_cooler",
    build_percentage: 2,
  },
  {
    component: "gpu",
    build_percentage: 20,
  },
  {
    component: "case",
    build_percentage: 5,
  },
  {
    component: "case_fans",
    build_percentage: 5,
  },
  {
    component: "operating_system",
    build_percentage: 5,
  },
];

function buying_power_data_to_distribution_data(
  backend_data: Buying_Power_Distribution
): Distribution_Data[] {
  return [
    {
      component: "case",
      build_percentage: backend_data.case.build,
    },
    {
      component: "case_fan",
      build_percentage: backend_data.case_fan.build,
    },
    {
      component: "cpu",
      build_percentage: backend_data.cpu.build,
    },
    {
      component: "cpu_cooler",
      build_percentage: backend_data.cpu_cooler.build,
    },
    {
      component: "gpu",
      build_percentage: backend_data.gpu.build,
    },
    {
      component: "motherboard",
      build_percentage: backend_data.motherboard.build,
    },
    {
      component: "operating_system",
      build_percentage: backend_data.operating_system.build,
    },
    {
      component: "psu",
      build_percentage: backend_data.psu.build,
    },
    {
      component: "ram",
      build_percentage: backend_data.ram.build,
    },
    {
      component: "storage",
      build_percentage: backend_data.storage.build,
    },
  ];
}

const renderLabel = function (entry: { component: string }) {
  return entry.component;
};

const BitLotPieChart = (props: Props) => {
  const [pieChartData, setPieChartData] = useState<
    Distribution_Data[] | undefined
  >(undefined);

  useEffect(() => {
    setPieChartData(
      props.data
        ? buying_power_data_to_distribution_data(props.data)
        : undefined
    );
  }, [props.data]);

  return (
    <main style={chart_style}>
      <Grid container sx={{ width: props.width, height: props.height }} m={2}>
        <Grid item xs={12}>
          {!!props.title && (
            <Typography variant={"h6"} sx={{ textAlign: "center" }}>
              {props.title}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer width={"100%"} height={"80%"}>
            <PieChart width={400} height={400}>
              <Pie
                dataKey={"build_percentage"}
                isAnimationActive={false}
                data={
                  pieChartData
                    ? pieChartData.filter((el) => el.build_percentage > 0)
                    : sample_data
                }
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#39B54A"
                label={renderLabel}
                labelLine={false}
                nameKey="component"
              />
              <Tooltip
                formatter={(value) => {
                  return `${value}%`;
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        {pieChartData && (
          <Grid item xs={12}>
            <Typography variant="subtitle1">Not pictured:</Typography>
            <Typography variant="subtitle1">
              {pieChartData
                .filter((el) => el.build_percentage === 0)
                .map((el) => el.component)
                .toLocaleString()
                .replaceAll(",", ", ")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </main>
  );
};

export default BitLotPieChart;
